<template>
  <b-btn
    variant="danger"
    size="sm"
    v-b-modal="idModal"
    v-b-tooltip.hover
    :title="$t('views.help.buttons.delete-file')"
    id="delete-help-file-button"
  >
    <b-icon-trash />
    <confirm
      :id="idModal"
      :title="$t('views.help.modal.delete-file')"
      :message="$t('views.help.modal.delete-file-message')"
      @onOk="removeFile()"
      variantHeader="danger"
      variantText="light"
    />
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
import Confirm from "@/components/Confirm.vue";

export default {
  components: {
    Confirm,
  },
  props: {
    idFile: Number,
  },
  data() {
    return {
      idModal: "delete-help-file-modal" + this.idFile,
    };
  },
  methods: {
    ...mapActions("Help", ["deleteFile"]),
    removeFile() {
      this.deleteFile({
        idFile: this.idFile,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.text, {
            variant: "success",
            title: this.$t("views.help.modal.delete-file"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(errorMsg, {
            variant: "danger",
            title: this.$t("views.help.modal.delete-file"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.$bvModal.hide(this.idModal);
        });
    },
  },
};
</script>
