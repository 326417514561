<template>
  <div>
    <b-row class="mb-3">
      <b-col md="9">
        <h3>{{ $t("views.home.help") }}</h3>
      </b-col>
      <b-col md="3" class="text-right">
        <b-form @submit.prevent="">
          <b-input-group size="sm">
            <b-form-input
              type="search"
              :placeholder="$t('general.search')"
              v-model="SearchHelpWord"
              id="HelpSearchInput"
            ></b-form-input>
            <CreateArticle
              class="ml-2"
              v-if="HideNoCustomerAdmin"
              @closeModals="refreshList"
            />
          </b-input-group>
        </b-form>
      </b-col>
    </b-row>
    <b-card
      v-for="helpArticle in SearchCases"
      :key="helpArticle.idArticle"
      no-body
      class="mb-3"
    >
      <b-card-header>
        <div>
          <b-row>
            <b-col md="10"
              ><h4 class="text-break">{{ helpArticle.articleName }}</h4></b-col
            >
            <b-col md="2" class="text-right"
              ><h5>{{ FormatDate(helpArticle.articleDate) }}</h5></b-col
            >
          </b-row>
        </div>
      </b-card-header>
      <b-card-body class="p-0">
        <b-list-group>
          <b-list-group-item>
            {{ helpArticle.articleDescription }}
          </b-list-group-item>
        </b-list-group>
        <b-table
          v-if="helpArticle.articleFiles.length > 0"
          class="ml-2"
          small
          fixed
          :items="helpArticle.articleFiles"
          :fields="fieldsTable"
        >
          <template #table-busy>
            <div><simple-loader /></div>
          </template>
          <template v-slot:cell(fileName)="row">
            <div>
              <b-icon :icon="iconsExtension(row.item.fileName)" />
              {{ row.item.fileName }}
              <b-button
                class="ml-1"
                scale="0.9"
                variant="outline-secondary"
                v-b-tooltip.hover
                :title="$t('views.help.modal.download-files')"
                size="sm"
                @click="downloadFile(row.item)"
              >
                <b-icon icon="download" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card-body>
      <b-card-footer>
        <div>
          <b-button-group size="sm">
            <UpdateArticle
              v-if="HideNoCustomerAdmin"
              :idArticle="helpArticle.idArticle"
              @closeModals="refreshList"
            />
            <HelpEntryDelete
              v-if="HideNoCustomerAdmin"
              :idHelpArticle="helpArticle.idArticle"
            />
          </b-button-group>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import CreateArticle from "@/components/Help/HelpCreateArticle.vue";
import UpdateArticle from "@/components/Help/HelpUpdateArticle.vue";
import HelpEntryDelete from "@/components/Help/HelpEntryDelete.vue";

export default {
  components: {
    CreateArticle,
    UpdateArticle,
    HelpEntryDelete,
  },
  data() {
    return {
      SearchHelpWord: "",
    };
  },
  computed: {
    ...mapGetters({
      UserRole: "GET_USERROLES",
    }),
    ...mapGetters("Help", ["helpArticles"]),
    HideNoCustomerAdmin() {
      return this.UserRole.isAdminCustomer;
    },
    fieldsTable() {
      const title = [
        {
          key: "fileName",
          label: this.$t("views.help.file-name-title"),
        },
      ];
      return title;
    },
    SearchCases() {
      let search = this.helpArticles.filter((x) =>
        x.articleName
          .toLocaleLowerCase()
          .includes(this.SearchHelpWord.toLocaleLowerCase())
      );
      if (search.length === 0) {
        search = this.helpArticles.filter((x) =>
          x.articleDescription
            .toLocaleLowerCase()
            .includes(this.SearchHelpWord.toLocaleLowerCase())
        );
      }
      return this.SearchHelpWord !== ""
        ? this.sortCasesList(search)
        : this.sortCasesList(this.helpArticles);
    },
  },
  methods: {
    ...mapActions("Help", ["getHelpArticles", "getDownloadFileArticle"]),
    sortCasesList(arrayObject) {
      let lisOfCasesSorted = arrayObject;
      return lisOfCasesSorted;
    },
    FormatDate(date) {
      if (date) {
        return moment
          .utc(String(date))
          .local()
          .format(this.$t("views.help.dateformat"));
      }
      return "";
    },
    refreshList() {
      this.loading = true;
      this.getHelpArticles()
        .then(() => {})
        .catch((e) => {
          console.log(e.response.data.text);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadFile(fileSelected) {
      this.getDownloadFileArticle({
        idFile: fileSelected.idFile,
      })
        .then((response) => {
          let blob = new Blob([response.data]);

          var filename = "";
          var docExtension = ".pdf";
          var disposition = response.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            filename.substring(0, filename.length - docExtension.length) +
              docExtension
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t("views.help.modal.temporal-errorMessage"),
            {
              variant: "danger",
              title: this.$t("views.help.modal.download-files"),
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.onShow();
        });
    },
    iconsExtension(nameFile) {
      var fileExt = nameFile.split(".").pop();
      switch (fileExt) {
        case "xls":
          return "file-earmark-excel";
        case "jpg":
          return "file-earmark-image";
        default:
          return "file-earmark";
      }
    },
  },
  mounted() {
    this.loading = true;
    this.getHelpArticles()
      .then(() => {})
      .catch((e) => {
        console.log(e.response.data.text);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped></style>
