<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal="idModal"
    v-b-tooltip.hover
    :title="$t('views.help.buttons.delete-article')"
    id="update-help-entry-button"
  >
    <b-icon icon="trash" />
    <confirm
      :id="idModal"
      :title="$t('views.help.modal.delete-article')"
      :message="$t('views.help.modal.delete-article-message')"
      @onOk="removeArticle"
      variantHeader="danger"
      variantText="light"
    />
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
import Confirm from "@/components/Confirm.vue";
export default {
  components: {
    Confirm,
  },

  props: {
    idHelpArticle: Number,
  },

  data() {
    return {
      idModal: "delete-help-entry-modal" + this.idHelpArticle,
    };
  },

  methods: {
    ...mapActions("Help", ["deleteArticle"]),
    removeArticle() {
      this.deleteArticle({
        idHelpArticle: this.idHelpArticle,
      })

        .then((response) => {
          this.$bvToast.toast(response.data.text, {
            variant: "success",
            title: this.$t("views.help.modal.delete-article"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })

        .catch((failure) => {
          this.$bvToast.toast(failure, {
            variant: "danger",
            title: this.$t("views.help.modal.delete-article"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })

        .finally(() => {
          this.$bvModal.hide(this.idModal);
        });
    },
  },
};
</script>
