<template>
  <b-btn
    variant="success"
    size="sm"
    v-b-modal="idModal"
    v-b-tooltip.hover
    :title="$t('views.help.buttons.edit-file')"
    id="new-cost-type-button"
  >
    <b-icon-pencil-square />

    <b-modal
      :id="idModal"
      ref="new-file"
      :title="$t('views.help.modal.edit-file')"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      :cancel-title="$t('general.cancel')"
      :ok-title="$t('general.ok')"
      :busy="loading"
      @ok="handleOk"
      @hidden="resetModal"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :label="$t('views.help.modal.edit-file-label')"
          label-for="file-input"
          :invalid-feedback="invalidFeedbackMessage"
          label-cols="12"
          content-cols="12"
          :state="state.file"
        >
          <b-form-file
            id="file-input"
            accept=".pdf"
            :placeholder="$t('views.help.modal.edit-file-placeholder')"
            :browse-text="$t('views.help.modal.browse-file-placeholder')"
            v-model="form.file"
            :state="state.file"
            @change="handleFileUpload"
            required
          ></b-form-file>
        </b-form-group>
      </form>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    idFile: Number,
    idArticle: Number,
  },
  data() {
    return {
      form: {
        file: null,
        fileArray: null,
      },
      state: {
        file: null,
      },
      idModal: "new-file-modal" + this.idFile,
      loading: false,
      invalidFeedbackMessage: this.$t("views.help.modal.invalid-feedback-file"),
    };
  },
  methods: {
    ...mapActions("Help", ["putFile"]),
    checkFormValidity() {
      let valid = (this.state.file = true);
      if (!this.form.file) {
        this.state.file = valid = false;
        this.invalidFeedbackMessage = this.$t(
          "views.help.modal.invalid-feedback-file"
        );
      }
      if (this.form.file.size > 5000000) {
        this.state.file = false;
        valid = false;
        this.invalidFeedbackMessage = this.$t("views.help.modal.file-size");
      }
      if (this.form.file) {
        var fileExt = this.form.file.name.split(".").pop();
        if (fileExt !== "pdf") {
          this.state.file = false;
          valid = false;
          this.invalidFeedbackMessage = this.$t("views.help.modal.file-no-pdf");
        }
      }
      return valid;
    },
    handleFileUpload(event) {
      if (!this.form.file) {
        this.form.file = event.target.files[0];
        const that = this;
        var reader = new FileReader();
        this.loading = true;
        reader.onload = function () {
          var arrayBuffer = this.result,
            array = new Uint8Array(arrayBuffer);
          that.form.fileArray = array;
          that.loading = false;
        };
        reader.readAsArrayBuffer(this.form.file);
      }
    },
    resetModal() {
      this.form = {
        file: null,
      };
      this.state = {
        file: null,
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.loading = true;
      this.putFile({
        idFile: this.idFile,
        idArticle: this.idArticle,
        data: this.form.fileArray,
        fileName: this.form.file.name,
        contentType: this.form.file.type,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.information.text, {
            variant: "success",
            title: this.$t("views.help.modal.edit-file"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(errorMsg, {
            variant: "danger",
            title: this.$t("views.help.modal.edit-file"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.$bvModal.hide(this.idModal);
          this.loading = false;
        });
    },
  },
};
</script>
