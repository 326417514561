<template>
  <div>
    <b-modal
      :id="idModal"
      ref="upload-help-modal"
      :title="$t('views.help.modal.upload-files')"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      header-bg-variant="primary"
      header-text-variant="light"
      @show="onShow"
      v-model="modalShow"
    >
      <b-table
        small
        hover
        fixed
        :busy="loading"
        :items="helpFiles"
        :fields="fieldsTable()"
      >
        <template #table-busy>
          <div><simple-loader /></div>
        </template>
        <template #head()="data">
          <div>
            <b-row>
              <b-col md="9" class="text-break">
                {{ data.label }}
              </b-col>
              <b-col md="3" class="text-right">
                <b-button-group size="sm">
                  <NewFile :idArticle="idArticle" />
                </b-button-group>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-slot:cell(fileName)="row">
          <div>
            <b-row>
              <b-col md="9" class="text-break">
                <b-icon :icon="iconsExtension(row.item.fileName)" />
                {{ row.item.fileName }}
              </b-col>
              <b-col md="3" class="text-right">
                <b-button-group size="sm">
                  <EditFile :idFile="row.item.idFile" :idArticle="idArticle" />
                  <DeleteFile :idFile="row.item.idFile" />
                </b-button-group>
              </b-col>
            </b-row>
          </div>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button variant="primary" @click="onOK" :disabled="loading">{{
            $t("views.help.buttons.close")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NewFile from "@/components/Help/HelpNewFile.vue";
import EditFile from "@/components/Help/HelpEditFile.vue";
import DeleteFile from "@/components/Help/HelpDeleteFile.vue";
export default {
  components: {
    NewFile,
    EditFile,
    DeleteFile,
  },
  props: {
    idArticle: Number,
    openModal: Boolean,
  },
  data() {
    return {
      idModal: "upload-help-article-modal" + this.idArticle,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("Help", ["helpFiles"]),
    modalShow() {
      return this.openModal;
    },
  },
  methods: {
    ...mapActions("Help", ["getHelpFiles"]),
    fieldsTable() {
      const title = [
        {
          key: "fileName",
          label: this.$t("views.help.file-name-title"),
        },
      ];
      return title;
    },
    onShow() {
      this.loading = true;
      this.getHelpFiles({
        idArticle: this.idArticle,
      })
        .then(() => {})
        .catch((e) => {
          console.log(e.response.data.text);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    iconsExtension(nameFile) {
      var fileExt = nameFile.split(".").pop();
      switch (fileExt) {
        case "xls":
        case "xlsx":
          return "file-earmark-excel";
        case "jpg":
        case "png":
          return "file-earmark-image";
        default:
          return "file-earmark";
      }
    },
    onOK() {
      this.$emit("onOkFiles");
    },
  },
};
</script>
