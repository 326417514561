<template>
  <b-btn
    variant="primary"
    size="sm"
    v-b-modal="idModal"
    v-b-tooltip.hover
    :title="$t('views.help.buttons.update-article')"
    id="update-help-article-button"
  >
    <b-icon-pencil-square />
    <b-modal
      :id="idModal"
      ref="update-help-article-modal"
      :title="$t('views.help.modal.update-article')"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      :cancel-title="$t('general.cancel')"
      :ok-title="$t('views.help.buttons.next')"
      :busy="loading"
      @ok="handleOk"
      @hidden="resetModal"
      @show="onShow"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :label="$t('views.help.modal.create-article-label-name')"
          label-for="name-input"
          :invalid-feedback="$t('views.help.modal.invalid-feedback-name')"
          label-cols="12"
          content-cols="12"
          :state="state.name"
        >
          <b-form-input
            id="name-input"
            :disabled="loading"
            :placeholder="
              $t('views.help.modal.create-article-placeholder-name')
            "
            v-model="form.name"
            :state="state.name"
            maxlength="40"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          :label="$t('views.help.modal.create-article-label-description')"
          label-for="description-input"
          :invalid-feedback="
            $t('views.help.modal.invalid-feedback-description')
          "
          label-cols="12"
          content-cols="12"
          :state="state.description"
        >
          <b-form-textarea
            id="description-input"
            :disabled="loading"
            :placeholder="
              $t('views.help.modal.create-article-placeholder-description')
            "
            v-model="form.description"
            :state="state.description"
            maxlength="1000"
          ></b-form-textarea>
        </b-form-group>
      </form>
    </b-modal>
    <UploadFileModal
      :openModal="openFileModal"
      :idArticle="idArticle"
      @onOkFiles="onOkFiles"
    />
  </b-btn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UploadFileModal from "@/components/Help/HelpUploadFiles.vue";

export default {
  props: {
    idArticle: Number,
  },
  components: {
    UploadFileModal,
  },
  data() {
    return {
      form: {
        name: null,
        description: null,
      },
      state: {
        name: null,
        description: null,
      },
      idModal: "update-help-article-modal" + this.idArticle,
      openFileModal: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("Help", ["helpArticle"]),
  },
  methods: {
    ...mapActions("Help", ["getHelpArticle", "putArticle"]),
    checkFormValidity() {
      let valid = (this.state.name = this.state.description = true);
      if (!this.form.name) this.state.name = valid = false;
      return valid;
    },
    resetModal() {
      this.form = {
        name: null,
        description: null,
      };
      this.state = {
        name: null,
        description: null,
      };
      this.loading = false;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    onOkFiles() {
      this.openFileModal = false;
      this.$bvModal.hide(this.idModal);
      this.$emit("closeModals");
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.loading = true;
      this.putArticle({
        idArticle: this.idArticle,
        ArticleName: this.form.name,
        ArticleDescription: this.form.description,
      })
        .then((response) => {
          this.$bvToast.toast(response.data.information.text, {
            variant: "success",
            title: this.$t("views.help.modal.update-article"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.openFileModal = true;
        })
        .catch((errorMsg) => {
          this.$bvToast.toast(errorMsg, {
            variant: "danger",
            title: this.$t("views.help.modal.update-article"),
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading = false;
          this.state = {
            name: null,
            description: null,
          };
        });
    },
    onShow() {
      this.loading = true;
      this.getHelpArticle({
        idArticle: this.idArticle,
      })
        .then(() => {
          this.form.name = this.helpArticle.articleName;
          this.form.description = this.helpArticle.articleDescription;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
